import { WritableSignal, computed, signal } from '@angular/core';
import { EntityCapacity } from '@modules/product-matrix/types/matrix';
import { LocalizedStatus } from './cluster.type';
import { FieldFieldType } from './field.type';
import { Limit, LimitDeviation } from './limits';
import { NamedItem } from './named-item.type';
import { ListWithPagination } from './response.type';

export type UpdateStrategyMatrix = Array<UpdateStrategyMatrixItem>;

export type UpdateStrategyMatrixItem = {
  item_id: number;
  branch: number[];
  count: number;
};

export type UpdateStrategyRequest = {
  start_date: string;
  name: string;
  draft: boolean;
};

export type CreateStrategyRequest = {
  name: string;
  based_on: number | string;
  for: StrategyFor;
  on_date: string;
  start_date: string;
  category_id: number;
  strategy_fields_attributes: {
    order: number;
    field_id: number;
  }[];
  strategy_product_statuses_attributes: {
    product_status_id: number;
  }[];
};

export type StrategyFieldValue = {
  b_value: boolean | null;
  field_reference_id: number | null;
  id: number;
  field_id: number;
  field_localized_name: string;
  field_name: string;
  field_reference_name: string | null;
  field_type: FieldFieldType;
};

export type StrategyField = AvailableStrategyField & {
  field_id: number;
  order: number;
  strategy_field_values: StrategyFieldValue[];
};

export type AvailableStrategyField = {
  id: number;
  name: string;
  field_type: FieldFieldType;
  localized_field_type: string;
  localized_name: string;
};

export enum StrategyBase {
  Matrix = 'matrix',
}

export enum StrategyFor {
  Stores = 'stores',
  Clusters = 'clusters',
}

export type StrategyItemsResponse = {
  strategy_items: ({ item_id: number; name: string } & EntityCapacity)[];
};

export type StrategyItems = {
  items: (NamedItem & EntityCapacity)[];
};

class BaseStrategyMatrixValue {
  add(key: ItemId, value: StrategyMatrixValueItem) {
    Object.assign(this, { [key]: value });
  }

  reset(resetObj: StrategyMatrixValue) {
    Object.keys(resetObj).forEach((key) => {
      const value = resetObj[Number(key)];
      value.reset();
    });
  }
}

export class StrategyMatrixValue extends BaseStrategyMatrixValue {
  [x: number]: StrategyMatrixValueItem;
}

export class StrategyMatrixValueItem {
  value: WritableSignal<number>;

  hasInnerDeviation: WritableSignal<boolean>;

  deviated: WritableSignal<boolean>;

  edited = computed(() => this.value() !== this.#defaultValue && this.#defaultValue !== null);

  attachedLimitIds: number[] = [];

  deviatedLimitIds: number[] = [];

  #defaultValue: number = 0;

  constructor(data: StrategyMatrix['key'][0]) {
    this.#defaultValue = data[0];
    const isInnerDeviation = data[1];
    this.attachedLimitIds = data[2];
    this.deviatedLimitIds = data[3];

    this.hasInnerDeviation = signal(isInnerDeviation && !this.deviatedLimitIds.length);
    this.deviated = signal(isInnerDeviation && !!this.deviatedLimitIds.length);
    this.value = signal(this.#defaultValue);
  }

  reset() {
    this.value.set(this.#defaultValue);
  }
}

export type StrategyMatrix = Record<ItemId, Record<FieldId, [number, boolean, number[], number[]]>>;

export type StrategyMatrixWithPresentValues = {
  matrix: StrategyMatrix;
  presentValues: FieldId[];
};

export type ItemId = number | string;

export type FieldId = number;

export type StrategyMatrixResponse = {
  matrix: StrategyMatrix;
  meta: { present_values: Array<FieldId> };
};

export type StrategyListResponse = ListWithPagination<
  {
    strategies: SimpleStrategyList;
  },
  { has_processing_records: boolean }
>;

export type SimpleStrategyItem = {
  id: number;
  name: string;
  based_on: StrategyBase;
  for: StrategyFor;
  on_date: string;
  start_date: string;
  status: LocalizedStatus;
  strategy_fields: Array<StrategyField>;
};

export type StrategyItem = SimpleStrategyItem & {
  is_matrix_created: boolean;
  strategy_limits: Limit[];
  strategy_product_statuses: {
    id: number;
    product_status_id: number;
  }[];
  strategy_limit_deviations: LimitDeviation[];
};

export type SimpleStrategyList = SimpleStrategyItem[];

export class SimpleStrategyListRequest {
  constructor(
    public category_id: number,
    public q: {} = {},
    public per_page: number = -1,
  ) {}
}
